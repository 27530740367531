<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<v-row dense>
						<!-- SEQORDER -->
						<v-col cols="4">
							<pui-number-field
								:id="`seqorder-locationsequencestep`"
								v-model="model.seqorder"
								:label="$t('form.locationsequencestep.seqorder')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="10"
							></pui-number-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- LOCATIONFROM -->
						<v-col cols="4">
							<pui-select
								id="locationsequencestep-locationfrom"
								attach="locationsequencestep-locationfrom"
								:label="$t('form.locationsequencestep.locationfrom')"
								toplabel
								clearable
								v-model="model"
								modelName="location"
								:disabled="formDisabled"
								:modelFormMapping="{ id: 'locationfrom' }"
								:itemsToSelect="[{ id: model.locationfrom }]"
								itemValue="id"
								itemText="description"
								:order="{ description: 'asc' }"
								:fixedFilter="filterLocation"
								required
							></pui-select>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- LOCATIONTO -->
						<v-col cols="4">
							<pui-select
								id="locationsequencestep-locationto"
								attach="locationsequencestep-locationto"
								:label="$t('form.locationsequencestep.locationto')"
								toplabel
								clearable
								v-model="model"
								modelName="location"
								:disabled="formDisabled"
								:modelFormMapping="{ id: 'locationto' }"
								:itemsToSelect="[{ id: model.locationto }]"
								itemValue="id"
								itemText="description"
								:order="{ description: 'asc' }"
								:fixedFilter="filterLocation"
								required
							></pui-select>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- AVERAGETIME -->
						<v-col cols="4">
							<pui-text-field
								:id="`averagetime-locationsequencestep`"
								v-model="model.averagetime"
								:label="$t('form.locationsequencestep.averagetime')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="10"
							></pui-text-field>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns :formDisabled="formDisabled" :saveDisabled="saving" :save="save" :back="back"></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'locationsequencestep-form',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			modelName: 'locationsequencestep',
			seqOrders: [],
			portid: null
		};
	},
	methods: {
		afterGetData() {
			if (this.isCreatingElement) {
				//Fill data
				this.model.seqheaderid = JSON.parse(atob(this.$attrs.parentpk)).id;
			}
			this.getNextMovementNum();
			this.getHeaderPortId();
		},
		getNextMovementNum() {
			const opts = {
				model: 'locationsequencestep',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'seq_header_id',
							op: 'eq',
							data: JSON.parse(atob(this.$attrs.parentpk)).id
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data && response.data.data) {
					this.seqOrders = response.data.data.map((item) => item.seqorder);
					let maxOrder = Math.max(...this.seqOrders);
					if (this.isCreatingElement) {
						if (maxOrder > 0) {
							this.nextOrder = ++maxOrder;
						} else {
							this.nextOrder = 1;
						}
						this.model.seqorder = this.nextOrder;
					}
				} else {
					this.seqOrders = null;
					this.nextOrder = 1;
					this.model.seqorder = 1;
				}
			});
		},
		getHeaderPortId() {
			const opts = {
				model: 'locationsequenceheader',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'id',
							op: 'eq',
							data: JSON.parse(atob(this.$attrs.parentpk)).id
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data && response.data.data[0]) {
					this.portid = response.data.data[0].portid;
				} else {
					this.portid = null;
				}
			});
		}
	},
	computed: {
		allBerths() {
			return this.$store.state.operationsuser && this.berths ? this.berths : [];
		},
		filterByPortUser() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'port_id', op: 'in', data: this.$store.getters.getPortsIds }]
			};
		},
		filterByPort() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'port_id', op: 'eq', data: this.portid }]
			};
		},
		filterLocation() {
			return this.portid ? this.filterByPort : this.filterByPortUser;
		}
	},
	created() {}
};
</script>
